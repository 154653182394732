<template>
  <div v-if="isMobile" class="bottom-player-control">
    <div class="bottom-player-control-item-content">
      <div class="nowrap">
        <div class="bottom_navigation_content_content">
          <span class="bottom_navigation_content_subtitle">
            Reproduzindo - {{ getCurrentCourse.lessons_completed_count }} de {{ getCurrentCourse.lessons_count }}
          </span>
          <span class="bottom_navigation_content_title"
          >
            Próximo: {{ getNextLesson.title }}
          </span>
        </div>
      </div>
      <div class="nowrap">
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    ...mapGetters(["getCurrentCourse","getIsMobile", "getNextLesson"]),
  },
  methods: {
  },
};
</script>

<style scoped>
.bottom-player-control {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  flex-wrap: nowrap;
  height: 85px;
  background-color: var(--background2-v2);
}

.bottom_navigation_content_content {
  display: flex;
  padding: 26px;
  flex-direction: column;
}
.bottom_navigation_content_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--fontcolor-v2)
}
.bottom_navigation_content_subtitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--fontcolor2-v2)
}
</style>
